import { FrontendClient } from "@telus/frontend-common";
import {
  ConfigApi,
  DiscoveryApi,
  IdentityApi,
} from "@backstage/core-plugin-api";
import { ApiRef, createApiRef } from "@backstage/core-plugin-api";

/**
 * {@link @backstage/core-plugin-api#ApiRef}
 */

export const spocApiRef: ApiRef<SpocApi> = createApiRef<SpocApi>({
  id: "plugin.spoc.service",
});

type deps = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
};

type Prompt = {
  messages: Message[];
  lastPrompt: string;
  uuid: string;
  user: string;
};

type Message = {
  role: string;
  content: string;
};

export interface SpocApi {
  request: (prompt: Prompt, search: string) => Promise<any>;
  messageFeedback: (liked: any) => Promise<any>;
  getChatHistory: (user: string, type: string) => Promise<any>;
  deleteChatHistory: (id: string) => Promise<any>;
  deleteConversation: (conversationId: string, type: string) => Promise<any>;

  initGenesysChatSession: (chatSessionInfo: any) => Promise<any>;
  getGenesysMessages: <T>(
    sessionId: string,
    nexusToken: string,
    conversationID: string
  ) => Promise<T>;
  sendGenesysMessages: <T>(
    sessionId: string,
    nexusToken: string,
    text: string,
    conversationID: string,
    messages: any
  ) => Promise<T>;
  updateGenesysTranscript: <T>(
    conversationID: string,
    messages: any
  ) => Promise<T>;
  sendGenesysImage: <T>(
    sessionId: string,
    nexusToken: string,
    formData: any
  ) => Promise<T>;
  leaveGenesysChatSession: <T>(
    sessionId: string,
    nexusToken: string,
    participant: string,
    conversationID: string,
    prompt: any
  ) => Promise<T>;

  notifyGenesysTyping: <T>(
    sessionId: string,
    nexusToken: string,
    type: "start" | "stop"
  ) => Promise<T>;

  getContentfulAnnouncements: (lang: string) => Promise<any>;
  getContentfulInterruptAnnouncement: (lang: string) => Promise<any>;
  getContentfulOutAgeMessage: (lang: string) => Promise<any>;
  getContentfulAnnouncementById: (id: string, lang: string) => Promise<any>;
  
  getContentfulReleaseNotesDates: (lang: string) => Promise<any>;
  getContentfulReleaseNoteById: (id: string, lang: string) => Promise<any>;
  getContentfulLastReleaseNote: (lang: string) => Promise<any>;

  getContentfulQuickLinks: (lang: string) => Promise<any>;
  getContentfulReleaseNotesDatesKnowledgeBase: (lang: string) => Promise<any>;
  getContentfulReleaseNoteByIdKnowledgeBase: (id: string, lang: string) => Promise<any>;
  getContentfulLastReleaseNoteKnowledgeBase: (lang: string) => Promise<any>;

  getNotificationSound: () => Promise<any>;
  getMessageNotificationSound: () => Promise<any>;

  getFeedbackSurvey: () => Promise<any>;
  getFeedbackThumbs: () => Promise<any>;

  getSpocDBViewers: <T>() => Promise<T>;
  getSpocDBOnlyViewers: <T>() => Promise<T>;
  getSpocDBAdmins: <T>() => Promise<T>;
  postSpocDBAddViewer: <T>(email: string) => Promise<T>;
  deleteSpocDBDeleteViewer: <T>(email: string) => Promise<T>;

  getProducts: <T>() => Promise<T>;
  getFunctions: <T>() => Promise<T>;

  postSpocDBAddAdmin: <T>(
    email: string,
    kind: "standard" | "super"
  ) => Promise<T>;
  deleteSpocDBDeleteAdmin: <T>(email: string) => Promise<T>;

  getRPAList: <T>(
    initDate: string,
    finDate: string,
    pageSize: number,
    offset: number
  ) => Promise<T>;
  getRPAListTotals: <T>(initDate: string, finDate: string) => Promise<T>;

  getSpocDashboardTotals: <T>(initDate: string, finDate: string) => Promise<T>;
  updatePWResetRequests: <T>() => Promise<T>;
  updatePWResetRequestsByPendingTable: <T>() => Promise<T>;
  getChats: <T>(
    email: string | undefined,
    initDate: string,
    finDate: string,
    pageSize: number,
    offset: number
  ) => Promise<T>;
  getChatsByType: <T>(
    email: string | undefined,
    term: string | undefined,
    initDate: string,
    finDate: string,
    init: number,
    fin: number,
    type:
      | "solvedFirstTime"
      | "solvedSecondTime"
      | "noFeedback"
      | "notSolved"
      | "transferedToAgent",
    products: string[] | undefined,
    functions: string[] | undefined,
    thumbs: string[] | undefined
  ) => Promise<T>;
  getChatById: <T>(id: string) => Promise<T>;
  getChatsByDate: <T>(initDate: string, finDate: string) => Promise<T>;
  getChatUsersByDate: <T>(initDate: string, finDate: string) => Promise<T>;
  getHomeUsersByDate: <T>(initDate: string, finDate: string) => Promise<T>;

  getChatUsersNumberByDate: <T>(
    initDate: string,
    finDate: string
  ) => Promise<T>;
  getHomeUsersNumberByDate: <T>(
    initDate: string,
    finDate: string
  ) => Promise<T>;

  getSentimentByDate: <T>(initDate: string, finDate: string) => Promise<T>;
  getTopsDashboard: <T>(initDate: string, finDate: string) => Promise<T>;

  updateSurveyByButtonsHited: <T>(
    messages: any[],
    solved: boolean,
    chatID: string,
    step: 1 | 2
  ) => Promise<T>;

  voteSpocChat: <T>(
    chatID: string,
    vote: "positive" | "negative" | "neutral"
  ) => Promise<T>;

  // ########################## CONFIGURATIONs ##########################

  getConfigurationPrompt: <T>() => Promise<T>;
  getEticketPrompt: <T>() => Promise<T>;
  getVectorDBSpace: <T>() => Promise<T>;
  getGenesysNRMessage: <T>() => Promise<T>;
  getConfigurationModel: <T>() => Promise<T>;
  setConfigurationPrompt: <T>(prompt: string) => Promise<T>;
  setEticketPrompt: <T>(prompt: string) => Promise<T>;
  setVectorDBSpace: <T>(prompt: string) => Promise<T>;
  setConfigurationModel: <T>(model: string) => Promise<T>;
  setGenesysNRMessage: <T>(model: string) => Promise<T>;

  // ########################## MODEL TESTS ##########################
  testGPT35: () => Promise<any>;
  testGPT4o: () => Promise<any>;

  // ########################## VISITS ##########################

  recordHomePageVisit: <T>(email: string) => Promise<T>;

  // ########################## REPORTS GENERATOR ##########################

  generateCSVReport: <T>(
    reportName: string,
    initDate: string,
    endDate: string
  ) => Promise<T>;

  // ########################## CUSTOM RESPONSES ##########################
  addCustomResponseSPOCDashboard: <T>(
    prompt: string,
    response: string,
    responseFR: string
  ) => Promise<T>;

  getCustomResponsesSPOCDashboard: <T>(onlyActive?: boolean) => Promise<T>;

  updateCustomResponseSPOCDashboard: <T>(
    uuid: string,
    prompt: string,
    response: string,
    responseFR: string
  ) => Promise<T>;

  toggleCustomResponseSPOCDashboard: <T>(uuid: string) => Promise<T>;

  deleteCustomResponseSPOCDashboard: <T>(uuid: string) => Promise<T>;


  // ########################## SUGGESTED PROMPTS ##########################
  addSuggestedPromptSPOCDashboard: <T>(suggestedPromopt: any
  ) => Promise<T>;

  getSuggestedPromptsSPOCDashboard: <T>(onlyActive?: boolean) => Promise<T>;

  toggleSuggestedPromptSPOCDashboard: <T>(uuid: string) => Promise<T>;

  deleteSuggestedPromptSPOCDashboard: <T>(uuid: string) => Promise<T>;

  updateSuggestedPromptSPOCDashboard: <T>(
    uuid: string,
    suggestedPrompt: any
  ) => Promise<T>;

  // ########################## AGENT DIRECT CONN ##########################

  addAgentDirectConnSPOCDashboard: <T>(prompt: string) => Promise<T>;

  getAgentDirectConnSPOCDashboard: <T>(onlyActive?: boolean) => Promise<T>;

  updateAgentDirectConnSPOCDashboard: <T>(
    uuid: string,
    prompt: string
  ) => Promise<T>;

  toggleAgentDirectConnSPOCDashboard: <T>(uuid: string) => Promise<T>;

  deleteAgentDirectConnSPOCDashboard: <T>(uuid: string) => Promise<T>;

  // ########################## FEATURE FLAGS ##########################

  getFeatureFlags: <T>() => Promise<T>;
  toggleFeatureFlag: <T>(featureflag: string) => Promise<T>;
}

export class SpocClient extends FrontendClient implements SpocApi {
  protected readonly configApi: ConfigApi;

  constructor(deps: deps) {
    super({
      discoveryApi: deps.discoveryApi,
      identityApi: deps.identityApi,
      defaultPlugin: "gpt",
    });

    this.configApi = deps.configApi;
  }

  async request(prompt: Prompt, search: string) {
    return await this.postRequired("/chat", {
      prompt,
      search,
    });
  }

  async getChatHistory(user: string, type: string) {
    return await this.postRequired("/getChatHistory", {
      user,
      type,
    });
  }

  async deleteChatHistory(id: string) {
    return await this.postRequired("/delete", {
      conversationId: id,
      type: "Chat",
    });
  }

  async deleteConversation(conversationId: string, type: string) {
    return await this.postRequired("/delete", {
      conversationId,
      type,
    });
  }

  async messageFeedback(liked: any) {
    return await this.postRequired("/messageFeedback", {
      liked,
      source: "spoc",
    });
  }

  async initGenesysChatSession(chatSeesionInfo: string) {
    return await this.postRequired("/initGenesysChatSession", chatSeesionInfo);
  }

  async getGenesysMessages<T>(
    sessionId: string,
    nexusToken: string,
    conversationID: string
  ): Promise<T> {
    return await this.getRequired(
      `/getGenesysChatMessages/${sessionId}?conversationID=${conversationID}`,
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
        timeout: 30000
      }
    );
  }

  async sendGenesysMessages<T>(
    sessionId: string,
    nexusToken: string,
    text: string,
    conversationID: string,
    messages: any
  ): Promise<T> {
    return await this.postRequired(
      `/sendGenesysChatMessage/${sessionId}`,
      { message: text, conversationID, messages },
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async updateGenesysTranscript<T>(
    conversationID: string,
    messages: any
  ): Promise<T> {
    return await this.postRequired(
      `/updategenesystranscript`,
      { conversationID, messages },
      undefined
    );
  }

  async sendGenesysImage(
    sessionId: string,
    nexusToken: string,
    formData: any
  ): Promise<any> {
    return await this.postRequired(
      `/sendGenesysChatImage/${sessionId}`,
      formData,
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async notifyGenesysTyping<T>(
    sessionId: string,
    nexusToken: string,
    type: "start" | "stop"
  ): Promise<T> {
    return await this.postRequired(
      `/notifyGenesysTyping/${sessionId}/${type}`,
      undefined,
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async leaveGenesysChatSession<T>(
    sessionId: string,
    nexusToken: string,
    participant: string,
    conversationID: string,
    prompt: any
  ): Promise<T> {
    return await this.postRequired(
      `/leaveSession/${sessionId}/${participant}`,
      { conversationID, prompt },
      undefined,
      {
        headers: { "x-nexus-token": nexusToken },
      }
    );
  }

  async updateSurveyByButtonsHited(
    messages: any[],
    solved: boolean,
    chatID: string,
    step: 1 | 2
  ): Promise<any> {
    try {
      return await this.postRequired(
        `/spocsurvey/buttons`,
        { messages, solved, chatID, step },
        undefined
      );
    } catch (error) {
      return { result: "error", error: (error as Error).message || error };
    }
  }

  async voteSpocChat(
    chatID: string,
    vote: "positive" | "negative" | "neutral"
  ): Promise<any> {
    try {
      return await this.postRequired(
        `/spocdashboard/vote`,
        { chatID, vote },
        undefined
      );
    } catch (error) {
      return { result: "error", error: (error as Error).message || error };
    }
  }

  async getContentfulAnnouncements<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulAnnouncements?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulReleaseNotesDates<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulReleaseNotesDates?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulLastReleaseNote<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulLastReleaseNote?lang=${lang}`,
      undefined,
      {}
    );
  }

  // ########################## Release Notes knowledge base ##########################

  async getContentfulQuickLinks<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulQuickLinks?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulReleaseNotesDatesKnowledgeBase<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulReleaseNotesDatesKnowledgeBase?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulLastReleaseNoteKnowledgeBase<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulLastReleaseNoteKnowledgeBase?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulReleaseNoteByIdKnowledgeBase<T>(id: string, lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulReleaseNoteByIdKnowledgeBase/${id}?lang=${lang}`,
      undefined,
      {}
    );
  }

  // ###############################################################################

  async getContentfulInterruptAnnouncement<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulInterruptAnnouncement?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulOutAgeMessage<T>(lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulOutAgeMessage?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getContentfulAnnouncementById<T>(id: string, lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulAnnouncementById/${id}?lang=${lang}`,
      undefined,
      {}
    );
  }
  async getContentfulReleaseNoteById<T>(id: string, lang: string): Promise<T> {
    return await this.getRequired(
      `/contentfulReleaseNoteById/${id}?lang=${lang}`,
      undefined,
      {}
    );
  }

  async getFeedbackSurvey<T>(): Promise<T> {
    return await this.getRequired(
      `/messagesFeedback/spocSurveyFeedbackMsgs`,
      undefined,
      {}
    );
  }

  async getSpocDashboardTotals<T>(
    initDate: string,
    finDate: string
  ): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/totals?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async updatePWResetRequests<T>(): Promise<T> {
    return await this.postRequired(
      `/spocdashboard/updaterpastatus`,
      {},
      undefined
    );
  }

  async updatePWResetRequestsByPendingTable<T>(): Promise<T> {
    return await this.postRequired(
      `/spocdashboard/updaterpastatusbypendingtable`,
      {},
      undefined
    );
  }

  async getChats<T>(
    email: string | undefined,
    initDate: string,
    finDate: string,
    pageSize: number,
    offset: number
  ): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/chats?${
        email && `email=${email}&`
      }initDate=${initDate}&endDate=${finDate}&pageSize=${pageSize}&offset=${offset}`,
      undefined,
      {}
    );
  }

  async getChatsByType<T>(
    email: string | undefined,
    term: string | undefined,
    initDate: string,
    finDate: string,
    init: number,
    fin: number,
    type:
      | "solvedFirstTime"
      | "solvedSecondTime"
      | "noFeedback"
      | "notSolved"
      | "transferedToAgent",
    products: string[] | undefined,
    functions: string[] | undefined,
    thumbs: string[] | undefined
  ): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/chatsbytype?${email && `email=${email}&`}${
        term && `term=${term}&`
      }initDate=${initDate}&endDate=${finDate}&init=${init}&fin=${fin}&type=${type}${
        products ? `&products=${products.join(",")}` : ""
      }${functions ? `&functions=${functions.join(",")}` : ""}${
        thumbs ? `&thumbs=${thumbs.join(",")}` : ""
      }`,
      undefined,
      {}
    );
  }

  async getChatById<T>(id: string): Promise<T> {
    return await this.getRequired(`/spocdashboard/chat/${id}`, undefined, {});
  }

  async getRPAList<T>(
    initDate: string,
    finDate: string,
    pageSize: number,
    offset: number
  ): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/rpalist?initDate=${initDate}&endDate=${finDate}&pageSize=${pageSize}&offset=${offset}`,
      undefined,
      {}
    );
  }

  async getRPAListTotals<T>(initDate: string, finDate: string): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/rpalisttotals?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getChatsByDate<T>(initDate: string, finDate: string): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/totalsbydate?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getChatUsersByDate<T>(initDate: string, finDate: string): Promise<T> {
    return await this.getRequired(
      `/spocvisits/totalusersbydate?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getHomeUsersByDate<T>(initDate: string, finDate: string): Promise<T> {
    return await this.getRequired(
      `/spocvisits/totalhomeusersbydate?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getChatUsersNumberByDate<T>(
    initDate: string,
    finDate: string
  ): Promise<T> {
    return await this.getRequired(
      `/spocvisits/totalusersnumberbydate?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getHomeUsersNumberByDate<T>(
    initDate: string,
    finDate: string
  ): Promise<T> {
    return await this.getRequired(
      `/spocvisits/totalnumberhomeusersbydate?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getSentimentByDate<T>(initDate: string, finDate: string): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/sentimentbydate?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getProducts<T>(): Promise<T> {
    return await this.getRequired(`/spocdashboard/products`, undefined, {});
  }

  async getFunctions<T>(): Promise<T> {
    return await this.getRequired(`/spocdashboard/functions`, undefined, {});
  }

  async getSpocDBViewers<T>(): Promise<T> {
    return await this.getRequired(`/spocdashboardviewers`, undefined, {});
  }

  async getSpocDBOnlyViewers<T>(): Promise<T> {
    return await this.getRequired(`/spocdashboardonlyviewers`, undefined, {});
  }

  async getSpocDBAdmins<T>(): Promise<T> {
    return await this.getRequired(`/spocdashboardadmins`, undefined, {});
  }

  async postSpocDBAddViewer<T>(email: string): Promise<T> {
    return await this.postRequired(
      `/spocdashboardviewer`,
      { email },
      undefined
    );
  }

  async deleteSpocDBDeleteViewer<T>(email: string): Promise<T> {
    return await this.postRequired(
      `/spocdashboardviewerdelete`,
      { email },
      undefined
    );
  }

  async postSpocDBAddAdmin<T>(
    email: string,
    kind: "standard" | "super"
  ): Promise<T> {
    const response = await this.postRequired(
      `/spocdashboardadmin`,
      { email, kind },
      undefined
    );

    return response as T;
  }

  async deleteSpocDBDeleteAdmin<T>(email: string): Promise<T> {
    return await this.postRequired(
      `/spocdashboardadmindelete`,
      { email },
      undefined
    );
  }

  async getTopsDashboard<T>(initDate: string, finDate: string): Promise<T> {
    return await this.getRequired(
      `/spocdashboard/tops?initDate=${initDate}&endDate=${finDate}`,
      undefined,
      {}
    );
  }

  async getFeedbackThumbs<T>(): Promise<T> {
    return await this.getRequired(
      `/messagesFeedback/spocThumbsFeedbackMsgs`,
      undefined,
      {}
    );
  }

  async getNotificationSound<T>(): Promise<T> {
    return await this.getRequired("/notification", undefined, {
      responseType: "blob",
    });
  }

  async getMessageNotificationSound<T>(): Promise<T> {
    return await this.getRequired("/agentmessagesound", undefined, {
      responseType: "blob",
    });
  }

  async getConfigurationPrompt<T>(): Promise<T> {
    return await this.getRequired(
      "/spocconfig/configurationprompt",
      undefined,
      {}
    );
  }

  async getEticketPrompt<T>(): Promise<T> {
    return await this.getRequired(
      "/spocconfig/eticketprompt",
      undefined,
      {}
    );
  }

  async getVectorDBSpace<T>(): Promise<T> {
    return await this.getRequired("/spocconfig/vectordbspace", undefined, {});
  }

  async getConfigurationModel<T>(): Promise<T> {
    return await this.getRequired(
      "/spocconfig/configurationmodel",
      undefined,
      {}
    );
  }

  async setConfigurationPrompt<T>(prompt: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/configurationprompt`,
      { prompt },
      undefined
    );
  }

  async setEticketPrompt<T>(prompt: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/eticketprompt`,
      { prompt },
      undefined
    );
  }

  async setVectorDBSpace<T>(prompt: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/vectordbspace`,
      { prompt },
      undefined
    );
  }

  async setConfigurationModel<T>(model: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/configurationmodel`,
      { model },
      undefined
    );
  }

  async getGenesysNRMessage<T>(): Promise<T> {
    return await this.getRequired(
      "/spocconfig/configurationgnrmessage",
      undefined,
      {}
    );
  }

  async setGenesysNRMessage<T>(message: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/configurationgnrmessage`,
      { message },
      undefined
    );
  }

  // ########################## MODEL TESTS ##########################
  async testGPT35<T>(): Promise<T> {
    return await this.getRequired(`/testgpt35`, undefined, {});
  }

  async testGPT4o<T>(): Promise<T> {
    return await this.getRequired(`/testgpt4o`, undefined, {});
  }

  // ########################## VISITS ##########################

  async recordHomePageVisit<T>(email: string): Promise<T> {
    return await this.postRequired("/spocvisits/homepage", {
      email,
    });
  }

  // ########################## REPORTS ##########################

  async generateCSVReport<T>(
    reportName: string,
    initDate: string,
    endDate: string
  ): Promise<T> {
    return await this.getBlobRequired(
      `/spoccsvgenerator/generate/${reportName}?initdate=${initDate}&enddate=${endDate}`
    );
  }


  // ########################## SUGGESTED PROMPTS ##########################

  async addSuggestedPromptSPOCDashboard<T>(
    suggestedPrompt: any
  ): Promise<T> {
    return await this.postRequired(`/spocconfig/sp/createsuggestedPrompt`, {
      suggestedPrompt
    });
  }

  async toggleSuggestedPromptSPOCDashboard<T>(uuid: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/sp/togglesuggestedprompt/${uuid}`,
      {}
    );
  }

  async deleteSuggestedPromptSPOCDashboard<T>(uuid: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/sp/deletesuggestedprompt/${uuid}`,
      {}
    );
  }

  async updateSuggestedPromptSPOCDashboard<T>(
    uuid: string,
    suggestedPrompt: any
  ): Promise<T> {
    return await this.postRequired(
      `/spocconfig/sp/updatesuggestedprompt/${uuid}`,
      {
        suggestedPrompt
      }
    );
  }
  

  async getSuggestedPromptsSPOCDashboard<T>(
    onlyActive: boolean = false
  ): Promise<T> {
    return await this.getRequired(
      `/spocconfig/sp/getsuggestedpromptslist${
        onlyActive ? "?onlyactive=true" : ""
      }`
    );
  }

  // ########################## CUSTOM RESPONSES ##########################

  async addCustomResponseSPOCDashboard<T>(
    prompt: string,
    response: string,
    responseFR: string
  ): Promise<T> {
    return await this.postRequired(`/spocconfig/cr/createcustomresponse`, {
      prompt,
      response,
      responseFR,
    });
  }

  async updateCustomResponseSPOCDashboard<T>(
    uuid: string,
    prompt: string,
    response: string,
    responseFR: string
  ): Promise<T> {
    return await this.postRequired(
      `/spocconfig/cr/updatecustomresponse/${uuid}`,
      {
        prompt,
        response,
        responseFR,
      }
    );
  }

  async toggleCustomResponseSPOCDashboard<T>(uuid: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/cr/togglecustomresponse/${uuid}`,
      {}
    );
  }

  async deleteCustomResponseSPOCDashboard<T>(uuid: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/cr/deletecustomresponse/${uuid}`,
      {}
    );
  }

  async getCustomResponsesSPOCDashboard<T>(
    onlyActive: boolean = false
  ): Promise<T> {
    return await this.getRequired(
      `/spocconfig/cr/getcustomresponseslist${
        onlyActive ? "?onlyactive=true" : ""
      }`
    );
  }

  // ########################## AGENT DIRECT CONN ##########################

  async addAgentDirectConnSPOCDashboard<T>(prompt: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/adcc/createagentdirectconncondition`,
      {
        prompt,
      }
    );
  }

  async updateAgentDirectConnSPOCDashboard<T>(
    uuid: string,
    prompt: string
  ): Promise<T> {
    return await this.postRequired(
      `/spocconfig/adcc/updateagentdirectconncondition/${uuid}`,
      {
        prompt,
      }
    );
  }

  async toggleAgentDirectConnSPOCDashboard<T>(uuid: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/adcc/toggleagentdirectconncondition/${uuid}`,
      {}
    );
  }

  async deleteAgentDirectConnSPOCDashboard<T>(uuid: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/adcc/deleteagentdirectconncondition/${uuid}`,
      {}
    );
  }

  async getAgentDirectConnSPOCDashboard<T>(
    onlyActive: boolean = false
  ): Promise<T> {
    return await this.getRequired(
      `/spocconfig/adcc/getagentdirectconnconditionlist${
        onlyActive ? "?onlyactive=true" : ""
      }`
    );
  }

  // ########################## FEATURE FLAGS ##########################

  async getFeatureFlags<T>(): Promise<T> {
    return await this.getRequired(`/spocconfig/fueatureflags`);
  }

  async toggleFeatureFlag<T>(featureflag: string): Promise<T> {
    return await this.postRequired(
      `/spocconfig/togglefeatureflag/${featureflag}`,
      {}
    );
  }
}
