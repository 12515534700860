import { createContext } from 'react';
import { t } from './translations';

export interface NavTabSrcTypes {
  [key: string]: {
    label: string;
    href?: string;
    tabPanel?:
      | undefined
      | {
          leftCard: {
            title: string;
            links: {
              [key: string]: {
                title: string;
                href: string;
              };
            };
          };
          rightCard: {
            [key: string]: {
              title: string;
              content: string;
              linkTitle: string;
              href: string;
            };
          };
        };
  };
}

export const NavTabSrcContext = createContext<NavTabSrcTypes>(
  {} as NavTabSrcTypes,
);

//  Edit this object to make all Navbar changes

export const getNavTabSrc = (lang: string): NavTabSrcTypes => {
  return {
    Home: {
      label: t.home[lang],
      href: '/',
    },
    Catalog: {
      label: t.catalogues[lang],
      tabPanel: {
        leftCard: {
          title: t.exploreCatalogues[lang],
          //  Maximum of 6 links
          links: {
            '0': {
              title: t.documentationCatalogue[lang],
              href: '/docs',
            },
            '1': {
              title: t.telusCatalogue[lang],
              href: '/catalog',
            },
            '2': {
              title: t.aacTemplateLanding[lang],
              href: '/aac-template-landing',
            },
            '3': {
              title: t.productInventory[lang],
              href: '/product-inventory',
            },
          },
        },
        rightCard: {
          //  maximum of 2 adCards for size
          '0': {
            title: t.unicornAiCardTitle[lang],
            content: t.unicornAiCardContent[lang],
            linkTitle: 'unicorn.ai',
            href: '/gpt',
          },
          '1': {
            title: t.templatesCardTitle[lang],
            content: t.templatesCardContent[lang],
            linkTitle: t.templatesCardButton[lang],
            href: '/create',
          },
        },
      },
    },
    Applications: {
      label: t.applications[lang],
      tabPanel: {
        leftCard: {
          title: t.exploreApplications[lang],
          links: {
            // ensure to view nav in both desktop and mobile views if additional values are added
            '0': {
              title: t.apiTools[lang],
              href: '/api-tools',
            },
            '1': {
              title: t.apiMarketplace[lang],
              href: '/api-marketplace',
            },
            '2': {
              title: t.cioMentorship[lang],
              href: '/cio-tec-mentorship',
            },
            '3': {
              title: t.cloudManagement[lang],
              href: '/cloud-management',
            },
            '4': {
              title: t.dynatraceMonitor[lang],
              href: '/dynatrace-monitor',
            },
            '5': {
              title: t.externalHealthcheck[lang],
              href: '/external-health-check',
            },
            '6': {
              title: t.genAILaunchpad[lang],
              href: '/gpt',
            },
            '7': {
              title: t.securityMetrics[lang],
              href: '/security-metrics',
            },
            '8': {
              title: t.tdrmIssues[lang],
              href: '/tdrm',
            },
            // '9': {
            //   title: t.dse[lang],
            //   href: '/dse-analytics-hub',
            // },
          },
        },
        rightCard: {
          //  maximum of 1 adCard for size
          '0': {
            title: t.iccComponentCardTitle[lang],
            content: t.iccComponentCardContent[lang],
            linkTitle: t.iccComponentCardButton[lang],
            href: '/icc',
          },
          '1': {
            title: t.tascComponentCardTitle[lang],
            content: t.tascComponentCardContent[lang],
            linkTitle: t.tascComponentCardButton[lang],
            href: '/tasc',
          },
          '2': {
            title: t.browseOurVideoLibrary[lang],
            content: t.videoLibraryDescription[lang],
            linkTitle: t.simplifyVideoLibrary[lang],
            href: '/video-library',
          },
        },
      },
    },
    Resources: {
      label: t.resources[lang],
      href: '/external',
    },
    Settings: {
      label: t.settings[lang],
      href: '/settings',
    },
  };
};
